.time--option--button {
    height: 8vw;
    background-color: #FAF4E8;
    border-radius: 50%;
    margin: 1vw;
    cursor: pointer;
}

.time--option--button img {
    height: 100%;
    position: relative;
    top: .8vw;
}

.outlineHalfVw {
    outline: 0.5vw dashed #eed1a3;
}