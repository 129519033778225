.tableHead .box {
	font-size: 3vw;
	background-color: #eed1a3;
	color: white;
	padding-top: 1vw;
}

.tableData .box {
	font-size: 2vw;
	background-color: #faf4e8;
}

.tableHead,
.tableData {
	display: flex;
	justify-content: flex-start;
	background-color: #f8edd6;
	margin-top: 1vw;
	padding: 0.2vw;
	height: 6vw;
}

.tableHead .box,
.tableData .box {
	width: 30%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-left: 1vw;
	padding-left: 2vw;
	text-align: start;
}

.tableHead .box--imageTitle {
	background-color: #eed1a3;
	color: white;
	min-width: 6vw;
	font-size: 4vw;
}

.tableData img {
	background-color: white;
	width: 6vw;
}

.tableHead :last-child,
.tableData :last-child {
	border-top-right-radius: 1vw;
	border-bottom-right-radius: 1vw;
}

.tableHead :first-child,
.tableData :first-child {
	border-top-left-radius: 1vw;
	border-bottom-left-radius: 1vw;
}

.add--box {
	background-color: #8cabc4;
	height: 6vw;
	font-size: 5vw;
	display: flex;
	justify-content: center;
	color: #f8edd6;
	border-radius: 1vw;
	cursor: pointer;
	margin-top: 1vw;
}