.activity--data--box {
	font-size: 2vw;
	background-color: #faf4e8;
    width: 30%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-left: 1vw;
	padding-left: 2vw;
	text-align: start;
}

.activity--input--box {
    border: none;
    cursor: pointer;
}

.activity--input--box:focus {
    background-color: #EDBE73;
    outline: none;
	cursor: text;
}

.activity--header--box {
    font-size: 3vw;
	background-color: #eed1a3;
	color: white;
	padding-top: 1vw;
}