.priority--line {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 1vw 2vw 0 3vw;
}

.priority--line :last-child{
	border-top-right-radius: 1vw;
	border-bottom-right-radius: 1vw;
}

.priority--line :first-child{
	border-top-left-radius: 1vw;
	border-bottom-left-radius: 1vw;
}

.priority--line:hover > .priority--data {
    background-color: #eed1a3;
}

.priority--data{
    background-color: #faf4e8;
    height: 8vw;
    width: 12%;
    font-size: 2vw;
    
    margin-left: 1vw;
    padding: 0 1vw;
    line-height: 8vw;
}

.order--number {
    margin: 0 !important;
}

.moveUp--button, .moveDown--button {
    cursor: pointer;
}

.moveUp--button:hover, .moveDown--button:hover {
    background-color:#EDBE73 !important;
}

.image--preview {
    background-color: white;
}

.priority--data > img {
    height: 100%;
}

.id--preview {
    width: 50% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.delete--button {
    width: 8% !important;
    cursor: pointer;
    background-color: #fcdbd2;
}

.delete--button:hover {
    animation: highlightDeleteHover 2s 1;
}

@keyframes highlightDeleteHover
{
    0%      {background:#ed7373;}
    25%     {background:#eea3a3;}
    75%     {background:#eea3a3;}
    100%    {background:#ed7373;}
}