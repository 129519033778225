.storage--view--image{
    margin: 1vw;
    background-color: #FAF4E8;
    width: 10vw;
    height: 10vw;
    border-radius: 2vw;
}

.storage--view--image img{
    height: 10vw;
}

.form--upload{
    display: flex;
    justify-content: center;
}

input[type="file"] {
    border-radius: 2vw;
    cursor: pointer;
    opacity: 0;
}

.add--file--icon{
    position: absolute;
    margin: 0;
}

.add--file {
    display: flex;
    justify-content: center;
    height: 10vw;
    width: 10vw;
    font-size: 8vw;
    color: #f8edd6;
    background-color: #8CABC4;
    border-radius: 2vw;
    cursor: pointer;
}

.viewImage--overText {
    margin: 0;
    position: relative;
    top: -3vw;
    font-size: 1.5vw;
}

.curserPointer{
    cursor: pointer;
}