#overlay {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0,0,0,0.4);
}

#overlay > .center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: fixed;
}

#overlay > .center > img {
	width: 200px;
	animation: heartbeat 8s infinite;
}

@keyframes heartbeat {
	0%, 100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.15);
	}
}

.form-button {
	display: inline-block;
    padding: -1px -2px 5px 6px;
    border: 3px solid rgb(255, 201, 14);
    margin: 6px 3px 6px 3px;
    border-radius: 16px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Varela Round', sans-serif;
    color: white;
    text-align: center;
    background-color: rgb(255, 201, 14);
}

.info-button {
	display: inline-block;    
    border: 3px solid #e8534d;
    margin: 6px 3px 6px 3px;
    border-radius: 16px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Varela Round', sans-serif;
    color: white;
    text-align: center;
    background-color: #e8534d;
	padding-top: 6px;
	font-size: 30px;
}

.form-button:hover:not([disabled]) {
	color: rgb(255, 201, 14);
	background-color:white;
}

.info-button:hover:not([disabled]) {
	color: #e8534d;
	background-color:white;
}

.form-button:disabled, .form-button[disabled] {
	color: darkgray;
	background-color: gray;
	border: 3px solid darkgrey;
}

.done-button {
	font-size: 6vw;
}

.footer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	width: 70%;
	margin: 0 auto;
}

.form {
	grid-column-start: 2;
	grid-column-end: 4;
}

.undoRedo {
	grid-column: 4;
	align-self: center;
	justify-self: end;
}

@media only screen and (max-width: 768px){

    .footer {
		width: auto;
        margin: 0;
		grid-template-columns: 1fr;
	}

	.form {
		grid-column-start: 1;
		grid-column-end: 2;
	}

	.undoRedo {
		grid-column: 1;
		justify-self: center;
	}

	.info-button {
		font-size: 4vw;
		padding: 1.5vw 1vw .5vw 2vw;
	}

}

@media only screen and (min-width: 768px){

    .footer {
		width: 560px;
	}

	.done-button {
		font-size: 40px;
	}

	.undoRedo {
		margin-right: 16px;
	}

}