.block--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.activities--wrapper{
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 auto;
}

@media only screen and (max-width: 600px){
    
    .activities--wrapper{
        width: auto;
        margin: 0;
    }

}

@media only screen and (min-width: 768px){

    .activities--wrapper{
        width: 560px;

    }

}