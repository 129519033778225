.side-right-button {
	position: absolute;
	right: 0;
	top: 15px;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    margin: 0 !important;
}

/* for .form-button and .info-button see src/pages/project.css */
