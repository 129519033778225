.modal-overlay {
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.info-modal-button-container {
    text-align: center;
}

.info-modal-button {
    font-size: 25px !important;
}

.hidden {
    display: none;
    /* Hidden by default */
}

.modal-dialog {
    background-color: #f8edd6;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    border-radius: 1vw;
    width: 35%;
    /* Could be more or less, depending on screen size */
}

.modal--header {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px double #aaa;

}

.modal--header h2{
    color: #aaa;
    font-size: 2vw;
    width: 100%;
    margin: 0;
    direction: rtl;
}

.close {
    color: #aaa;
    align-self: flex-end;
    font-size: 2vw;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.margin--TopBottom {
    margin: 2vw 0;
}

.line--bottom {
    border-bottom: 1px solid #aaa;
}

.info-modal-body {
    width: 60%;
    margin: 0 auto;
    direction: rtl;
    text-align: right;
}

.info-modal-body ion-icon {
    padding: 0 3px;
    color: hotpink;
}

.info-modal-body .info {
    color: darkgray;
}

.section h3{
    font-weight: normal;
    font-size: 1.5vw;
    color: #aaa;
    width: 100%;
    margin: 0 0 1vw 0;
}

.block--modal--grid{
    justify-content: center;
}

.modal-item-image{
    background-color: #FAF4E8;
    border-radius: 1vw;
}

@media only screen and (max-width: 600px){
    .modal-dialog {
        width: 80%;
    }

    .info-modal-body {
        width: 100%;
    }

    .modal--header h2{
        font-size: 8vw;
    }

    .close {
        font-size: 8vw;
        align-self: flex-start;
    }

    .section h3{
        font-size: 6vw;
    }

    .modal-item-image{
        border-radius: 2vw;
    }
}