.block--title {
    position: absolute;
    width: 100%;
    bottom: -2.7vw;
}

.block--titleText {
    background-color: rgba(255, 255, 255, 0);
    width: 14.6vw;
    font-family: 'Varela Round', sans-serif;
    font-size: 2vw;
    text-align: center;
    color: #8CABC4;
    margin: 0px 0px 0px 2px;
    padding: 1.5vw 0.2vw 0.2vw 0.2vw;
    border: 0 solid transparent;
    transform: translate(0, -2.6vw);
}

.block--title:focus, .block--titleText:focus{
    background-color: #f8edd6;
    outline: none;
}

.error-text {
    background-color: rgba(255, 0, 0, 0.3);

}

.block--titleText.error-text, .block--titleText:focus.error-text{
    background-color: rgba(255, 0, 0, 0.3);
}

/* display in modal */
.modal--block--title .block--title {
    position: relative;
    width: 100%;
    bottom: 0;
}

.modal--block--title .block--titleText {
    font-size: 2vw;
    width: 100%;
    transform: translate(0, 0);
    padding: 0;
    margin: 0;
}

.block--title:focus, .block--titleText:focus{
    background-color: #FAF4E8;
    outline: none;
}

/* display for mobile */
@media only screen and (max-width: 600px){

    .block--titleText {
        width: 18.6vw;
        font-size: 2.5vw;
    }

    .modal--block--title .block--titleText {
        font-size: 8vw;
    }

}

@media only screen and (min-width: 768px){

    .block--titleText {
        width: 96px;
        font-size: 14px;
        padding: 5px 2px 2px 2px;
    }

    /* .block--title > .form {
        height: 1px;
        top: -35px;
        position: relative;
    } */

}