.management--header--button {
    border: none;
    margin: 0 0 0 1vw;
    padding: 0;
    width: auto;
    overflow: visible;
    background-color: #f8edd6;
    padding: 2vw 0;
    font-size: 2vw;
    font-family: 'Varela Round', sans-serif;
    width: 20vw;
    transform: skew(20deg);
	border-top-right-radius: 1vw;
    border-top-left-radius: 1vw;
}

.management--header--button--text {
    transform: skew(-20deg);
}

.opacityHalf{
    opacity: 0.5;
}

.opacityFull{
    opacity: 1;
}