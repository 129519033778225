.time-label--wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
	height: 8vw;
}

.time-label {
	font-size: 6vw;
	color: #EDBE73;
}

.time-label--wrapper img {
	height: 4.5vw;
	padding: 3px 12px;
}


@media only screen and (min-width: 768px){

    .time-label {
        font-size: 50px !important;
    }

	.time-label--wrapper {
		height: 25px;
		margin-bottom: 7px;
	}

	.time-label--wrapper img {
		height: 40px;
	}

}