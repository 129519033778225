.childsName--input {
    background-color: #faf4e8;
    font-family: "Varela Round", sans-serif;
    text-align: center;
    font-size: 7vw;
    color: #8cabc4;
    border: none;
    outline: none;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    position: absolute;
    top: 45vw;
    right: 50vw;    
}

.childsName--input:placeholder-shown:not(:focus) {
    animation: shake 3s;
    animation-iteration-count: infinite;
}



@keyframes shake {
    0% {
        transform: translateY(0px) rotate(-90deg);
    }
    2.5% {
        transform: translateY(3px) rotate(-92deg);
    }
    5% {
        transform: translateY(-3px) rotate(-88deg);
    }
    7.5% {
        transform: translateY(3px) rotate(-92deg);
    }
    10% {
        transform: translateY(-3px) rotate(-88deg);
    }
    15% {
        transform: translateY(0px) rotate(-90deg);
    }
    100% {
        transform: translateY(0px) rotate(-90deg);
    }
}

.childsName--input::placeholder {
    font-size: 5vw;
}

@media only screen and (max-width: 600px) {
    .childsName--input {
        background-color: #faf4e8;
        width: 100%;
        font-family: "Varela Round", sans-serif;
        text-align: center;
        font-size: 7vw;
        color: #8cabc4;
        border: none;
        outline: none;
        position: static;
        transform: none;
    }
    
    .childsName--input:placeholder-shown:not(:focus) {
        animation-name: none;
    }
}

@media only screen and (min-width: 768px){

    .childsName--input {
        font-size: 60px;
        top: 275px;
        right: 45vw;
    }

    .childsName--input::placeholder{
        font-size: 40px;
    }

}