.block--item {
	position: relative;
	background-color: rgb(180, 218, 219);
	width: 15vw;
	height: 15vw;
	margin: 1vw 1vw 3vw 1vw;
	justify-self: center;
}

.block--item:hover .block--dropdown {
	display: block;
}

.block--dropdown {
	display: none;
}

.block--img {
	background-color: #FAF4E8;
	width: 100%;
	border: 2px solid #8CABC4;
	border-style: dashed;
}

.Dropdown-image {
	display: inline-block;
	vertical-align: middle;
	height: 2vw;
	width: 2vw;
}

.block--modal--grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: left;
}

.modal-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 12px 6px;
}

.modal-item-image {
	width: 80px;
}

.modal-item-text {
	margin-top: -15px;
	font-size: 12px;
}

@media only screen and (max-width: 600px){

	.block--item {
		width: 20vw;
		height: 20vw;
	}

}

@media only screen and (min-width: 768px){

	.block--item {
		width: 100px;
		height: 100px;
		margin: 10x 6px;
	}

}