.activity--block{
    width: 20vw;
    height: 20vw;
    background-color: #FAF4E8;
    margin: .5vw;
}

.activity--block img{
    width: 100%;
}

.highlighted {
    outline: 0.5vw dashed #eed1a3;
}