@font-face {
	font-family: Varela Round;
	src: local("Varela Round"), url("./fonts/VarelaRound-Regular.ttf") format("truetype");
	/* src: url('./fonts/VarelaRound-Regular.eot');
	src: url('./fonts/VarelaRound-Regular.woff') format("woff"), url('./fonts/VarelaRound-Regular.otf') format("opentype"), */
}

body {
	background-color: #FAF4E8;
	margin: 0;
	font-family: 'Varela Round', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}