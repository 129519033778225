.priority--order{
    margin-top: 1vw;
}

.add--priority{
    background-color: #8cabc4;
    height: 6vw;
    font-size: 5vw;
    color: #f8edd6;
    border-radius: 1vw;
    cursor: pointer;
    margin-top: 1vw;
    margin: 1vw 2vw 0 2vw;
}