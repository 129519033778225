.Dropdown-root {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.Dropdown-menu {
	background-color: rgb(238, 238, 238);
	text-align: right;
	overflow-y: scroll;
	height: 16vw;
}

.Dropdown-placeholder {
	background-color: rgb(227, 228, 228);
	font-size: 1.2vw;
	height: 1vw;
	padding: 1vw 0;
}

.Dropdown-option{
	height: 2vw;
	font-size: 1.2vw;
}